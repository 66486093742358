export enum Etat {
  FAILED = "error",
  SUCCESS = "found",
  ISEXIST = "already",
  ISNOTEXIST = "notFound",
  FREEINPAYPRICE = "FreeInPayPrice",
  INCORRECTPRICE = "IncorrectPrice",
  BADLEVEL = "badLevel",
}

export enum BuiltinRoleAdmin {
  SUPER_ADMIN = "SUPER_ADMIN",

  ADMIN_DEALS = "ADMIN_DEALS",
  ADMIN_EVENTS = "ADMIN_EVENTS",
  ADMIN_COVOITURAGES = "ADMIN_COVOITURAGES",
  ADMIN_ACTUALITY = "ADMIN_ACTUALITY",
  ADMIN_MOBILE_MONEY_BOOT = "ADMIN_MOBILE_MONEY_BOOT",

  EMPLOYER_DEALS = "EMPLOYER_DEALS",
  EMPLOYER_EVENTS = "EMPLOYER_EVENTS",
  EMPLOYER_COVOITURAGES = "EMPLOYER_COVOITURAGES",
  EMPLOYER_ACTUALITY = "EMPLOYER_ACTUALITY",

  SMALL_LEVEL = "SMALL_LEVEL",
}
